// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast-container {
  width: auto;
  max-width: 400px;
  font-weight: 400 !important;
}
@media (max-width: 767px) {
  .Toastify__toast-container {
    padding: 0 0 20px 20px;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .Toastify__toast-container {
    padding: 0 20px 20px;
  }
}

.Toastify__progress-bar--default {
  background: rgb(71, 166, 245);
}

.push-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.push-notification__btn {
  font-size: 16px;
  padding: 8px;
  color: #f2f2f2;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
  outline: none;
  min-width: 120px;
  margin-left: 10px;
}
.push-notification__img {
  margin-right: 10px;
}
.push-notification__content {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/toastify/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,2BAAA;AACF;AACE;EALF;IAMI,sBAAA;IACA,WAAA;EAEF;AACF;AAAE;EAVF;IAWI,oBAAA;EAGF;AACF;;AAAA;EACE,6BAAA;AAGF;;AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAGF;AADE;EACE,eAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;AAGJ;AAAE;EACE,kBAAA;AAEJ;AACE;EACE,aAAA;AACJ","sourcesContent":[".Toastify__toast-container {\n  width: auto;\n  max-width: 400px;\n  font-weight: 400 !important;\n\n  @media (max-width: 767px) {\n    padding: 0 0 20px 20px;\n    width: 100%;\n  }\n\n  @media (max-width: 400px) {\n    padding: 0 20px 20px;\n  }\n}\n\n.Toastify__progress-bar--default {\n  background: rgb(71, 166, 245);\n}\n\n.push-notification {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  &__btn {\n    font-size: 16px;\n    padding: 8px;\n    color: #f2f2f2;\n    border-radius: 6px;\n    font-weight: 500;\n    cursor: pointer;\n    transition: all 0.4s ease;\n    position: relative;\n    display: inline-block;\n    outline: none;\n    min-width: 120px;\n    margin-left: 10px;\n  }\n\n  &__img {\n    margin-right: 10px;\n  }\n\n  &__content {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
