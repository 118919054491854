import routerBook from '../../../../routes/routerBook';
import logo from '../../../../assets/images/vectors/white-logo.svg';
import dayjs from 'dayjs';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { tertiaryConfig } from '../tertiaryConfig';
import { openModal } from '../../../../components/modal/redux/modalOperations';
import modalType from '../../../../components/modal/types';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../../pages/authentication/redux/authenticationOperations';
import { Logo } from '../../logo/Logo';
import { postVersionAlert } from '../../../../services/api/supportService/supportService';
import { updateData } from '../../../../services/api/profileService/profileService';
import { errorHandler } from '../../../toastify/redux/notifyOperations';

const TertiaryHeader = ({ t }) => {
    const { pathname } = useLocation()
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { name, avatar, avatarUrl, email } = useSelector(store => store.authState.data)
    const data = useSelector(store => store.authState.data)
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
        isMobile: window.innerWidth < 1100
    })

    useEffect(() => {
        setIsOpenMobileMenu(false)
    }, [pathname])
    const onShowReason = () => {
        dispatch(
            openModal({
                type: modalType.FEEDBACKVERSION_MODAL,
                data: {
                    callback: (reason, details) => onSwitchVersion(reason, details),
                },
            }),
        );
    }
    const onSwitchVersion = (reason, details) => {

        postVersionAlert(
            {
                email: data?.email,
                name: data?.name,
                comment: reason + (details != '' ? " (" + details + ") " : "")
            }
        ).then(() => {
            updateData({
                email: data.email,
                name: data.name,
                gender: data.gender,
                height: data.height,
                weight: data.weight,
                version: 1
            }).then(response => {
                window.location.reload();
            }).catch(error => {
                dispatch(errorHandler(error.message));
            });
        })
            .catch(({ message, status }) => {
                dispatch(errorHandler(message));
            })

    }
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
                isMobile: window.innerWidth < 1100

            })

        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)

        }
    }, [])
    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = isOpenMobileMenu ? 'hidden' : 'auto';
    }, [isOpenMobileMenu]);

    const logoClickHandler = () => {
        navigate(routerBook.dashboard);
        closeMobileMenu();
    };
    const logoutClickHandler = () => {
        dispatch(logoutUser());
    }

    const openMobileMenu = () => {
        setIsOpenMobileMenu(true);
    };

    const closeMobileMenu = () => {
        setIsOpenMobileMenu(false);
    };

    const getMobileButtonOpenMenu = () => (
        <>
            <input type="checkbox" id="menuToggle" className="header-mobile_menu" checked={isOpenMobileMenu}
                onChange={openMobileMenu} />
            <label className="header-mobile_menu-key" htmlFor="menuToggle">
                <div className="header-mobile_menu-icon">
                    <span />
                    <span />
                    <span />
                </div>
            </label>
        </>
    );

    return (
        <header className="header-tertiary">
            <div className="header-tertiary__left">

                <div className="header-tertiary__left_container-icon">

                    <img onClick={logoClickHandler} className="header-tertiary__left-logo" src={logo} alt="icon" />
                </div>

                {getMobileButtonOpenMenu()}

                <div className="header-tertiary__mobile_menu" />

                <div className="header-tertiary__left-date">
                    <p> {dayjs(new Date(Date.now())).format('MMMM D')}</p>{' '}
                    <span className="header-tertiary__left-date-today">{t('header_tertiary_today')}</span>
                </div>

            </div>
            <div onClick={setIsOpenMobileMenu.bind(null, false)} className={`header-tertiary__right-container ${isOpenMobileMenu ?
                'header-tertiary__right-show-menu' : ''}`}>

                <div className='header-tertiary__right'>
                    <div className="header-tertiary__right-nav-close" onClick={closeMobileMenu} />
                    <div className="header-tertiary__right-nav-mob">
                        <NavLink className={'header-tertiary__right-nav-mob-item'} to={routerBook.aboutMe}>
                            {t('profile_menu_about')}
                        </NavLink>
                        <NavLink className='header-tertiary__right-nav-mob-item' to={routerBook.account}>
                            {t('header_tertiary_account')}
                        </NavLink>
                        {/* {data?.version == 2 &&
                            <a onClick={onShowReason} className='header-tertiary__right-nav-mob-item'  >Classic Website</a>
                        } */}
                        <NavLink className={'header-tertiary__right-nav-mob-item'} to={routerBook.contactUs}>
                            {t('header_tertiary_contact_Us')}
                        </NavLink>

                        <p className={'header-tertiary__right-nav-mob-item'}
                            onClick={logoutClickHandler}>
                            {t('header_tertiary_logaut')}
                        </p>

                    </div>

                    <nav className="header-tertiary__right-nav">
                        {tertiaryConfig.map(({ title, iconMobile, icon: Icon, redirect }, index) => (
                            <NavLink
                                end
                                key={title + index + redirect}
                                to={redirect}
                                className="header-tertiary__right-nav-item"
                                onClick={closeMobileMenu}>
                                {dimensions.isMobile ?
                                    <img src={iconMobile} className="header-tertiary__right-nav-item-img" /> :
                                    <Icon className="header-tertiary__right-nav-item-img" />}
                                <p>{t(title)}</p>
                            </NavLink>
                        ))}
                    </nav>
                    <div onClick={() => navigate(routerBook.profile)} className="header-tertiary__right-user">
                        {/* <img className="header-tertiary__right-user-arrow-mobile"
                             src={dimensions.isMobile ? arrowDownMobile : arrowDown} alt="arrow"/> */}
                        <span className="header-tertiary__right-user-title">{name} <small>{email}</small></span>
                        {/* <img className="header-tertiary__right-user-arrow" src={arrowDown} alt="arrow"/> */}
                        {avatar ? (
                            <img src={avatarUrl} alt="user" className="header-tertiary__right-user-avatar" />
                        ) : (
                            <span className="header-tertiary__right-user-avatar">{name[0]}</span>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};
export default withTranslation()(TertiaryHeader);
