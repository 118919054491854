// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  position: absolute;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-wrapper h1 {
  font-size: 48px;
  animation: logoLoaderAnim 1s infinite ease-in-out;
  color: #fe6d02;
  font-family: "SFProDisplay-Bold", sans-serif;
  background-color: red;
  background-image: linear-gradient(93.21deg, #e76200 0.92%, #ff9445 98.24%);
  background-size: 200% 200%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

@keyframes logoLoaderAnim {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,iDAAA;EACA,cAAA;EACA,4CAAA;EACA,qBAAA;EACA,0EAAA;EACA,0BAAA;EACA,yBAAA;EACA,6BAAA;EACA,oCAAA;EACA,iCAAA;AACJ;;AAGA;EACE;IACE,yBAAA;EAAF;EAGA;IACE,0BAAA;EADF;AACF","sourcesContent":[".loader-wrapper {\n  position: absolute;\n  overflow: auto;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  h1 {\n    font-size: 48px;\n    animation: logoLoaderAnim 1s infinite ease-in-out;\n    color: #fe6d02;\n    font-family: \"SFProDisplay-Bold\", sans-serif;\n    background-color: red;\n    background-image: linear-gradient(93.21deg, #e76200 0.92%, #ff9445 98.24%);\n    background-size: 200% 200%;\n    background-repeat: repeat;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    -moz-text-fill-color: transparent;\n  }\n}\n\n@keyframes logoLoaderAnim {\n  from {\n    background-position: 100%;\n  }\n\n  to {\n    background-position: -100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
