// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5176470588);
  z-index: 100;
  top: 0;
  left: 0;
  overflow: auto;
}
.modal-close {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,uCAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".modal {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  background: #00000084;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  overflow: auto;\n\n  &-close {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
