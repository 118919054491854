import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import router from '../routes/router';
import { store } from '../store/store';
import '../assets/styles/globalStyles.scss';
import '../localization/i18n';
import '../assets/styles/_fonts.scss';
import './styles.scss';

import { InstallPWA } from "../pages/question/component/InstallPWA/InstallPWA";

function App() {
    return (
        <>
            {/* <InstallPWA/> */}
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </>
    );
}

export default App;
