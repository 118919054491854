export const onboardingHints = [
  {
    type: 'week',
    id: '1',
  },
  {
    type: 'video',
    id: '2',
  },
  {
    type: 'workuot',
    id: '3',
  },
  {
    type: 'view_plan',
    id: '4',
  },
];
