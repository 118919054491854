import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import { Button } from '../../../ui-core';
import './styles.scss';
import { rateWorkout } from '../../../../services/api/workoutsService/workoutsService';
import { useDispatch } from 'react-redux';
import { errorHandler } from '../../../toastify/redux/notifyOperations';
import { getHintsStatus, putHintsStatus } from '../../../../services/api/profileService/profileService';
import { Rating } from 'react-simple-star-rating';

export const RatePopUp = ({ onClose, data, t }) => {
    const dispatch = useDispatch();

    const [showHint, setShowHint] = useState(false);
    const [rate, setRate] = useState(0);
    const [difficulty, setDifficulty] = useState(0);
    const [reason, setReason] = useState('');
    const onSend = () => {
        if (rate && difficulty) {
            onClose && onClose();
            rateWorkout(difficulty, rate, reason, data.day, data.workoutId)
                .then(response => {
                    if (data?.callback)
                        // {
                        data.callback(response.action);
                    // } else {
                    //     onClose && onClose();
                    // }
                })
                .catch(error => dispatch(errorHandler(error)));
        }
    };
    useEffect(() => {
        getHintsStatus().then(data => {
            setShowHint(data);
        });
    }, []);

    const hintHandler = () => {
        putHintsStatus({
            ...showHint,
            ratePopup: false,
        }).then(data => setShowHint(data));
    };

    const closeHandler = () => {
        onClose && onClose();
        if (data?.callback && !data.preventReload)
            // {
            data.callback();
        // } else {
        //     onClose && onClose();
        // }
    };
    // setUsersRate.bind(null, 5)
    return (
        <>
            {showHint.ratePopup && (
                <span className={'next_hint'} onClick={hintHandler}>
                    Next
                </span>
            )}

            {/* ========= rate pop up start =========== */}
            <div className="default-modal">
                <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} />
                <p className="default-modal-title">How was your workout?</p>
                <p className="popup-select-description">
                    Please rate the difficulty from 1-5 <span>(5 being the hardest).</span>
                </p>
                <div className="popup-select-container">
                    <div
                        className={`popup-select-container-hint${showHint.ratePopup ? ' hint rate-popup__hint' : ''}`}
                    />
                    <Button
                        onClick={() => setDifficulty(1)}
                        className={`popup-select-rate${difficulty === 1 ? ' popup-selected-rate' : ''}`}
                    >
                        1
                    </Button>
                    <Button
                        onClick={() => setDifficulty(2)}
                        className={`popup-select-rate${difficulty === 2 ? ' popup-selected-rate' : ''}`}
                    >
                        2
                    </Button>
                    <Button
                        onClick={() => setDifficulty(3)}
                        className={`popup-select-rate${difficulty === 3 ? ' popup-selected-rate' : ''}`}
                    >
                        3
                    </Button>
                    <Button
                        onClick={() => setDifficulty(4)}
                        className={`popup-select-rate${difficulty === 4 ? ' popup-selected-rate' : ''}`}
                    >
                        4
                    </Button>
                    <Button
                        onClick={() => setDifficulty(5)}
                        className={`popup-select-rate${difficulty === 5 ? ' popup-selected-rate' : ''}`}
                    >
                        5
                    </Button>
                </div>
                <p className="popup-select-description">How did you like the workout?</p>
                <div className="popup-select-container">
                    <Rating onClick={setRate} allowHover={false} fillColor={'#fe6d02'} />
                </div>

                {rate < 3 && rate > 0 && (
                    <div className="reason-wrapper">
                        <textarea
                            className="dislike-reason"
                            placeholder="Please tell us what you did not like about the workout."
                            value={reason}
                            onChange={e => setReason(e.target.value)}
                        />
                    </div>
                )}

                <Button onClick={onSend} className={'popup-select-submit-not-now'}>
                    {' '}
                    Save
                </Button>
            </div>
            {/* ========= rate pop up end =========== */}
        </>
    );
};
